
import axios from "axios";
export default {
  async asyncData({ store, error, route, $getAPI }) {
    try {
      const result = await axios.all([
        $getAPI(`page_index`, null, route.query.preview),
      ]);

      // randomize diary items
      result[0].data.body.diary[1].data.items =
      result[0].data.body.diary[1].data.items.sort(() => Math.random() - 0.5);

      return {
        ...result[0].data,
      };
    } catch (e) {
      error({
        statusCode: 500,
        message: store.state.labels.error_generic,
      });
    }
  },
};
